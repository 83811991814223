import Vue from 'vue'
import App from './App.vue'
import AxiosPlugin from 'vue-axios-cors';
// import store from './store'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
// import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import http from './http'
import store  from './store'



Vue.use(VueAxios, axios)



Vue.component('v-select', vSelect)
Vue.component('date-picker', DatePicker)

new Vue({
    store,
    AxiosPlugin,
    http: http,
    router: router,
    render: h => h(App)
}).$mount('#app')



