<template>
  <div v-if="isOpen" class="backdrop" @mousedown="close">
    <div class="cartpopup" @mousedown.stop>
      <div class="popup_container">
        <div class="popup_header">
          <h3>Выгрузка КП из Базы</h3>
          <div class="cartpopup_close"
               @click="close"
          >
            <div></div>
          </div>
        </div>
        <div class="content">
          <div class="content_header">
            <div class="vsel">
              <v-select
                  class="style-chooser"
                  v-model="selected"
                  label="name"
                  :reduce="(option) => option.id"
                  :options="Customers"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Результаты не найдены <em>{{ search }}</em
                  >.
                  </template>
                  <em v-else style="opacity: 0.5">Старт.</em>
                </template>
              </v-select>
            </div>

            <div class="date_container">
              <date-picker v-model="time" range
                           type="date"
                           format="YYYY-MM-DD"
                           value-type="format"
              ></date-picker>
            </div>

          </div>
          <div class="content_product">
            <table id='my-table-id' class="table">
              <thead>
              <tr>
                <th class="offer-1">№</th>
                <th class="offer-2">Дата</th>
                <th class="offer-3">Закзчик</th>
                <th class="offer-4">Данные</th>
                <th class="offer-2">Изменить</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in offer" :key="index">
                <td>{{ item.id }}</td>
                <td>{{ item.created }}</td>
                <td>{{ item.customer }}</td>
                <td class="date_request">
                  <!--                            -->


                  <table id="date_table" class="table">
                    <thead>
                    <!--                        <tr>-->
                    <!--                          <th class="prod-1">№</th>-->
                    <!--                          <th class="prod-2">Название</th>-->
                    <!--                          <th class="prod-3">Кол-во</th>-->

                    <!--                        </tr>-->
                    </thead>
                    <tbody>
                    <tr v-for="(prod, index) in item.data" :key="index">
                      <td class="prod-1">{{ index + 1 }}</td>
                      <td class="prod-2">{{ prod.name }}</td>
                      <td class="prod-3">{{ prod.count }}</td>
                    </tr>
                    </tbody>
                  </table>


                  <!--                            -->


                </td>
                <td>
                  <div class="addCart">

                      <span
                          class="add"
                          @click="addCard($event, index, items.index)"></span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>


          <div class="content_footer">
            <div>
              <div class="submit"
                   @click="confirmExel()"
              >Сформировать Exel
              </div>
            </div>
            <div class="submit"
                 @click="confirmWord()"
            >Сформировать Word
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from 'axios'
import 'vue-select/dist/vue-select.css';
import {AuthApi} from '@/_api';

export default {

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    Customers: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: {
    close: null,
    ok: null
  },
  data() {
    return {
      profits: 0,
      selected: null,
      time: null,
      timeFrom: null,
      timeTo: null,
      offer: []


      // options: [{name: "ОАО «Савушкин продукт»"}, {name: "СП «СантаБремор» ООО"}]
    }
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeydown);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleKeydown);
  },
  computed: {
    // sumBN() {
    //   const res = this.$store.state.cart.reduce(function (sum, elem) {
    //     return sum + parseFloat(parseFloat((elem.priceBN + (elem.priceBN * (elem.profit / 100))).toFixed(2)
    //         * elem.count).toFixed(2));
    //
    //   }, 0);
    //   return res.toFixed(2)
    // },
    // sum() {
    //   const res = this.$store.state.cart.reduce(function (sum, elem) {
    //     return sum + parseFloat(parseFloat((elem.priceBN + (elem.priceBN * (elem.profit / 100))).toFixed(2)
    //         * elem.count).toFixed(2));
    //
    //   }, 0);
    //   return (res * 1.2).toFixed(2)
    // },
  },

  watch: {
    // time: function (val) {
    //   console.log("time", val)
    //   // this.$store.commit('profitAll', val);
    //   // this.$store.commit('profitAllInc', val);
    // },

    time: {
      handler(newValue) {
        // console.log(newValue)
        this.timeFrom = newValue[0]
        this.timeTo = newValue[1]
        if (newValue[0] != null || this.selected != null) {
          this.getOffer()
        }
        if (newValue[0] == null || this.selected == null) {
          this.offer = []
        }


      },
    },
    selected: {
      handler(newValue) {
        if (newValue || this.timeFrom != null) {
          this.getOffer()
        }
        if (newValue == null || this.timeFrom == null) {
          this.offer = []
        }

      },
    },

  },

  methods: {
    vueXtoJson() {
      // console.log(JSON.stringify(this.$store.state.cart))


    },
    async getOffer() {

      const params = {
        customer: this.selected,
        timeTo: this.timeTo,
        timeFrom: this.timeFrom,
      }

      AuthApi.getOffer(params).then((res) => {
        this.offer = res.data
      }).catch(() => {
        this.$store.commit('showModal', "Ошибка получения предложений")

          }
      )

      // this.$store.dispatch("authModule/getOffer", {
      //    params: {
      //         customer: this.selected,
      //         timeTo: this.timeTo,
      //         timeFrom: this.timeFrom,
      //       }
      //
      // }).then((res) =>{
      //     // this.offer = res,
      //     console.log('hello', res)


      // this.axios.get(
      //     "http://127.0.0.1:8000/offer/all/",
      //     {
      //       params: {
      //         customer: this.selected,
      //         timeTo: this.timeTo,
      //         timeFrom: this.timeFrom,
      //       }
      //     })
      //     .then((res) => {
      //       console.log(res.data)
      //      this.offer = res.data
      //     })
    },
    // counts(e, index, direction) {
    //   if (direction) {
    //     e.target.previousElementSibling.stepUp()
    //     this.$store.commit('countInc', {index: index, value: e.target.previousElementSibling.value});
    //   } else {
    //     e.target.nextElementSibling.stepDown()
    //     this.$store.commit('countInc', {index: index, value: e.target.nextElementSibling.value});
    //   }
    // },
    // profit(e, index, direction) {
    //   if (direction) {
    //     e.target.previousElementSibling.stepUp()
    //     this.$store.commit('profitInc', {index: index, value: e.target.previousElementSibling.value});
    //   } else {
    //     e.target.nextElementSibling.stepDown()
    //     this.$store.commit('profitInc', {index: index, value: e.target.nextElementSibling.value});
    //   }
    // },
    handleKeydown(e) {
      if (this.isOpen && e.key === "Escape") {
        this.close();
      }
    },
    close() {
      this.$emit("close");
    },
    ok() {
      this.$emit("ok");
      this.$emit("close");
    },
    removeCard(e, index) {
      this.$store.commit('removeCart', index);
      this.$store.commit('removeIndex', index);
    },
  },
}
</script>
<style>

.date_container {
  padding-right: 5px;
}

.content_header .mx-table-date .today {
  color: red;
}

.mx-input-wrapper .mx-input:hover, .mx-input-wrapper .mx-input:focus {
  border-color: #42b983;
}

.mx-calendar-content .mx-table-date .today {
  color: #42b983;
}

.mx-calendar-content td.cell.active {
  background-color: #42b983;
}

.mx-calendar-content td.cell:hover {
  background-color: #42b983;
  color: white;
}

.mx-calendar-content td.cell.in-range {
  background-color: #9FE2BF;
}

.mx-calendar-content td.cell.hover-in-range {
  background-color: #9FE2BF;
  color: white;
}

/*.vs__dropdown-toggle:hover{*/
/*  border: 1px solid #42b983;*/
/*}*/
.offer-1 {
  max-width: 50px;
  width: 50px;
}

.offer-2, .offer-3 {
  max-width: 150px;
  width: 150px;
}

.offer-3 {
  max-width: 250px;
  width: 250px;
}

#date_table {
  margin: 0;
}

.table .date_request {
  padding: 0;
}

.prod-1 {
  max-width: 30px;
  width: 30px;
  background-color: white;

}

.prod-2 {
  background-color: white;

}

.prod-3 {
  max-width: 40px;
  width: 40px;
  background-color: white;

}

/*.cartpopup {*/
/*  position: absolute;*/
/*  top: 5%;*/
/*  left: 1%;*/
/*  width: 98%;*/
/*  background: white;*/
/*  box-sizing: border-box;*/
/*  box-shadow: 0 15px 25px rgba(0, 0, 0, .1);*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  cursor: default;*/
/*  border-top-left-radius: 8px;*/
/*  border-top-right-radius: 8px;*/
/*}*/

/*.popup_container {*/
/*  position: relative;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  height: 10vh;*/
/*  border-top-left-radius: 8px;*/
/*  border-top-right-radius: 8px;*/
/*}*/

/*.popup_header {*/
/*  height: 40px;*/
/*  padding: 8px;*/
/*  width: 100%;*/
/*  background-color: rgb(245, 245, 245);*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: space-between;*/
/*  border-bottom: 1px solid rgb(221, 221, 221);*/
/*  border-top-left-radius: 8px;*/
/*  border-top-right-radius: 8px;*/
/*}*/

/*.cartpopup_close {*/
/*  width: 30px;*/
/*  height: 30px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/

/*}*/

/*.cartpopup_close div {*/
/*  width: 20px;*/
/*  height: 20px;*/
/*  border-radius: 512px;*/
/*  background-size: cover;*/
/*  background: url(../../src/assets/close.svg);*/
/*}*/

/*.cartpopup_close:hover {*/
/*  background-color: rgb(221, 221, 221);*/
/*  border-radius: 512px;*/
/*}*/

/*.cartpopup_close div:hover {*/
/*  background: url(../../src/assets/close-hover.svg);*/
/*}*/

/*.content {*/
/*  background-color: white;*/
/*}*/

/*.content_header {*/
/*  height: 40px;*/
/*  !*background-color: #efefef;*!*/
/*  margin-top: 5px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: space-between;*/

/*}*/

/*.content_product {*/
/*  max-height: 75vh;*/
/*  margin-top: 5px;*/
/*  overflow-y: auto;*/
/*  overflow-x: hidden;*/


/*}*/

/*.content_footer {*/

/*  margin-top: 5px;*/
/*  display: flex;*/
/*  justify-content: flex-end;*/

/*}*/

/*.removeCart {*/
/*  display: flex;*/
/*  !*width: 40px;*!*/
/*  height: 40px;*/
/*  justify-content: center;*/
/*  align-items: center;*/

/*  cursor: pointer;*/

/*}*/

/*.removeCart span {*/
/*  display: block;*/
/*  width: 30px;*/
/*  height: 30px;*/
/*  background-size: cover;*/
/*  background: url(../../src/assets/minus.svg);*/

/*}*/

/*.removeCart:hover span {*/
/*  background: url(../../src/assets/minus-hover.svg);*/
/*}*/

/*.number {*/
/*  display: inline-block;*/
/*  position: relative;*/
/*  width: 70px;*/
/*}*/

/*.number input[type="number"] {*/
/*  display: block;*/
/*  height: 32px;*/
/*  line-height: 32px;*/
/*  width: 100%;*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  box-sizing: border-box;*/
/*  text-align: center;*/
/*  -moz-appearance: textfield;*/
/*  -webkit-appearance: textfield;*/
/*}*/

/*.number input[type="number"]::-webkit-outer-spin-button,*/
/*.number input[type="number"]::-webkit-inner-spin-button {*/
/*  display: none;*/
/*}*/

/*.number-minus {*/
/*  position: absolute;*/
/*  top: 1px;*/
/*  left: 1px;*/
/*  bottom: 1px;*/
/*  width: 20px;*/
/*  padding: 0;*/
/*  display: block;*/
/*  text-align: center;*/
/*  border: none;*/
/*  border-right: 1px solid #ddd;*/
/*  font-size: 16px;*/
/*  font-weight: 600;*/
/*}*/

/*.number-plus {*/
/*  position: absolute;*/
/*  top: 1px;*/
/*  right: 1px;*/
/*  bottom: 1px;*/
/*  width: 20px;*/
/*  padding: 0;*/
/*  display: block;*/
/*  text-align: center;*/
/*  border: none;*/
/*  border-left: 1px solid #ddd;*/
/*  font-size: 16px;*/
/*  font-weight: 600;*/
/*}*/

/*.allchange {*/
/*  margin-right: 10px;*/
/*}*/

/*.allProfit {*/
/*  margin-right: 10px;*/
/*}*/

/*.content_header .vsel {*/
/*  height: 30px;*/
/*  min-width: 350px;*/
/*  border-radius: 8px;*/
/*  margin-left: 10px;*/

/*}*/

/*.submit {*/
/*  border: 1px solid grey;*/
/*  border-radius: 8px;*/
/*  padding: 5px;*/
/*  margin: 20px;*/
/*  width: 120px;*/
/*  height: 50px;*/
/*  cursor: pointer;*/
/*  display: block;*/
/*  text-align: center;*/
/*}*/

/*.submit:hover {*/
/*  background-color: #42b983;*/
/*  color: white;*/
/*}*/


/*.vsel .v-select .vs__dropdown-option--highlight {*/
/*  background-color: #42b983;*/
/*}*/

/*.vsel .v-select .vs__dropdown-menu{*/
/*  max-height: 350px;*/
/*}*/

/*.vsel .v-select #vs8__listbox::-webkit-scrollbar{*/

/*  width: 14px;*/

/*}*/
/*.vsel .v-select #vs8__listbox::-webkit-scrollbar-thumb{*/

/*  border: 4px solid rgba(0, 0, 0, 0);*/
/*  background-clip: padding-box;*/
/*  border-radius: 9999px;*/
/*  background-color: #AAAAAA;*/

/*}*/


</style>
