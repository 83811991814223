import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import JSZipUtils from "jszip-utils";
import {saveAs} from "file-saver";

export const exportDocx = (tempDocxPath, fileName, data, time, sum, sumBN, customer, id) => {

    JSZipUtils.getBinaryContent(tempDocxPath, (error, content) => {

        if (error) {
            throw error
        }

        const zip = new PizZip(content)

        const doc = new Docxtemplater().loadZip(zip)

        doc.setData({
            "title": "The lorem chart",
            "product": data,
            time: time,
            sum: sum,
            sumBN: sumBN,
            sumNDS: (sum-sumBN).toFixed(2),
            customer: customer,
            id: id

        });
        try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render()
        } catch (error) {
            const e = {
                message: error.message,
                name: error.name,
                stack: error.stack,
                properties: error.properties
            }
            console.log({
                error: e
            })

            // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
            throw error
        }
        const out = doc.getZip().generate({
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        })
        saveAs(out, fileName)
    })
}
