<template>
  <div v-if="isOpen" class="backdrop" @mousedown="close">
    <div class="cartpopup" @mousedown.stop>
      <div class="popup_container">
        <div class="popup_header">
          <h3>Корзина</h3>
          <div class="cartpopup_close"
               @click="close"
          >
            <div></div>
          </div>
        </div>
        <div class="content">
          <div class="content_header">
            <div class="vsel">
              <v-select
                  class="style-chooser"
                  v-model="selected"
                  label="name"
                  :reduce="(option) => option.id"
                  :options="Customers"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Результаты не найдены <em>{{ search }}</em
                  >.
                  </template>
                  <em v-else style="opacity: 0.5">Старт.</em>
                </template>


              </v-select>
            </div>

            <div class="allchange">
              <label class="allProfit" for="peas">Общий процент наценки</label>
              <div class="number">
                <button class="number-minus" type="button"
                        @click="profits===0 ? profits=0 : profits--">-
                </button>
                <input type="number" min="0" value="0" name="peas" id="peas" v-model="profits">
                <button class="number-plus" type="button"
                        @click="profits++">+
                </button>
              </div>
            </div>

          </div>
          <div class="content_new_product">

            <div class="new_product"
            >
              <label for="part">Парт номер</label>
              <input id="part"
                     v-model="newProduct.sky"
              >
              <label for="name">Название</label>
              <input id="name"
                     v-model="newProduct.name"
              >
              <label for="sum">Цена без НДС</label>
              <input id="sum" type="number" step="0.01" @keypress="validateNumber"
                     v-model="newProduct.priceBN"
              >
              <button
                  :disabled="!buttonActive"
                  @click="formNewProductValidate">Добавить
              </button>
            </div>
          </div>


          <div class="content_product">
            <table id='my-table-id' class="table">
              <thead>
              <tr>
                <th class="cart-9">Из</th>
                <th class="cart-1">Парт №</th>
                <th class="cart-2">Название</th>
                <th class="cart-3">Кол-во</th>
                <th class="cart-4">Цена без НДС</th>
                <th class="cart-5">Нац-ка %</th>
                <th class="cart-6">Итого без НДС</th>
                <th class="cart-7">Итого с НДС</th>
                <th class="cart-8">Уда-ть</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(items, index) in fromStore" :key="index">
                <td>{{ items.from }}</td>
                <td>{{ items.sky }}</td>
                <td>{{ items.name }}</td>
                <td>
                  <div class="number">
                    <button class="number-minus" type="button"
                            @click="counts($event, index, false)">-
                    </button>
                    <input type="number" min="0" value="1" v-model="items.count">
                    <button

                        class="number-plus" type="button"
                        @click="counts($event, index, true)">+
                    </button>
                  </div>

                </td>
                <td>{{ items.priceBN }}</td>
                <td>
                  <div class="number">
                    <button class="number-minus" type="button"
                            @click="profit($event, index, false)">-
                    </button>
                    <input class="profit" type="number" min="0" value="0" v-model="items.profit">
                    <button class="number-plus" type="button"
                            @click="profit($event, index, true)">+
                    </button>
                  </div>
                </td>
                <td>{{
                    parseFloat(parseFloat((items.priceBN + (items.priceBN * (items.profit / 100))).toFixed(2)
                        * items.count).toFixed(2))
                  }}
                </td>
                <td>{{
                    parseFloat(parseFloat((items.priceBN + (items.priceBN * (items.profit / 100))).toFixed(2)
                        * items.count * 1.2).toFixed(2))
                  }}
                </td>
                <td>
                  <div class="removeCart"
                       @click="removeCard($event, index)"
                  >
                <span
                    class="remove"
                ></span>
                  </div>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr
                  v-if="$store.state.card.cart.length"
              >
                <td colspan="5">Итого</td>
                <td>{{ sumBN }}</td>
                <td>{{ sum }}</td>
                <td></td>
              </tr>
              </tfoot>
            </table>
          </div>


          <div class="content_footer">
            <div>
              <div class="submit"
                   @click="confirmExel()"
              >Сформировать Exel
              </div>
            </div>
            <div class="submit"
                 @click="confirmWord()"
            >Сформировать Word
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from 'axios'
import 'vue-select/dist/vue-select.css';
import {AuthApi} from '@/_api';

export default {

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    Customers: {
      type: Array,
      required: false,
      default: () => [{name: "ОАО «Савушкин продукт»"}, {name: "СП «СантаБремор» ООО"}]
    }
  },
  emits: {
    close: null,
    ok: null
  },
  data() {
    return {
      profits: 0,
      selected: '',
      newProduct: {
        index: '',
        from: 'U',
        sky: '',
        name: '',
        profit: 1,
        priceBN: 0,
        count: 1,
      },
      validateError: '',
      buttonActive: false

    }
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeydown);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleKeydown);
  },
  computed: {
    sumBN() {
      const res = this.$store.state.card.cart.reduce(function (sum, elem) {
        return sum + parseFloat(parseFloat((elem.priceBN + (elem.priceBN * (elem.profit / 100))).toFixed(2)
            * elem.count).toFixed(2));

      }, 0);
      return res.toFixed(2)
    },
    sum() {
      const res = this.$store.state.card.cart.reduce(function (sum, elem) {
        return sum + parseFloat(parseFloat((elem.priceBN + (elem.priceBN * (elem.profit / 100))).toFixed(2)
            * elem.count).toFixed(2));

      }, 0);
      return (res * 1.2).toFixed(2)
    },
    fromStore(){
      return this.$store.state.card.cart

    }

  },

  watch: {
    profits: function (val) {
      this.$store.commit('profitAll', val);
      this.$store.commit('profitAllInc', val);
    },
    newProduct: {
      handler(val) {
        console.log(val)
        if (val.name.length > 1 && val.priceBN.length > 0 && val.priceBN != '0') {
          this.buttonActive = true
        }
      },
      deep: true
    },



  },

  methods: {
    async saveDB() {

      const data = {
        customer: this.selected,
        data: this.$store.state.card.cart,
        price: this.sum,
        priceBN: this.sumBN
      }
      return await AuthApi.postOffer(data).then((res) => {
        return res
      }).catch((error) => {
        this.$store.commit('showModal', "Данные в базе не сохранены" + error);

      })
    },
    confirmExel() {
      this.$emit("confirmExel");
    },
    async confirmWord() {
      if (this.$store.state.card.cart.length) {
        try {
          const offerId = await this.saveDB()
          this.$emit("confirmWord", this.sum, this.sumBN, this.selected, offerId.data.id);

        } catch (e) {
          console.log(e)
        }
      } else {
        this.$store.commit('showModal', "Нет продуктов в корзине");
      }

    },
    counts(e, index, direction) {
      if (direction) {
        e.target.previousElementSibling.stepUp()
        this.$store.commit('countInc', {index: index, value: e.target.previousElementSibling.value});
      } else {
        e.target.nextElementSibling.stepDown()
        this.$store.commit('countInc', {index: index, value: e.target.nextElementSibling.value});
      }
    },
    profit(e, index, direction) {
      if (direction) {
        e.target.previousElementSibling.stepUp()
        this.$store.commit('profitInc', {index: index, value: e.target.previousElementSibling.value});
      } else {
        e.target.nextElementSibling.stepDown()
        this.$store.commit('profitInc', {index: index, value: e.target.nextElementSibling.value});
      }
    },
    handleKeydown(e) {
      if (this.isOpen && e.key === "Escape") {
        this.close();
      }
    },
    close() {
      this.$emit("close");
    },
    ok() {
      this.$emit("ok");
      this.$emit("close");
    },
    removeCard(e, index) {
      this.$store.commit('removeCart', index);
      this.$store.commit('removeIndex', index);
    },

    formNewProductValidate(e) {


      e.preventDefault();
      this.addNewProductCartStore()
    },

    addNewProductCartStore() {
      // console.log(this.filteredPriceMenu)

      const product = {
        index: 'U' + this.$store.state.card.index.length,
        from: 'U',
        sky: this.newProduct.sky,
        name: this.newProduct.name,
        profit: 1,
        priceBN: parseFloat(parseFloat(this.newProduct.priceBN).toFixed(2)),
        count: 1,



      }
      // this.newProduct.priceBN = parseFloat(parseFloat(product.priceBN).toFixed(2))
      this.$store.commit('addIndex', product.index);
      this.$store.commit('addCart', product);
      this.$store.commit('showModal', "Товар " + product.name + " добавлен в корзину");
      this.resetNewProduct()



    },

    resetNewProduct() {
      this.newProduct.name = ''
      this.newProduct.priceBN = 0
      this.newProduct.sky = ''
      this.buttonActive = false

    },

    validateNumber: (event) => {
      let charCode = event.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 44) {
        event.preventDefault();
      }


      //       if (event.value.indexOf(".") != '-1') {
      //   e.value=e.value.substring(0, e.value.indexOf(".") + 4); // цифра 4, устанавливает количество цифр после запятой,
      //                                                           //т.е. если 4, то максимум 3 цифры после запятой
      // }


    },
  },
}
</script>
<style>
.cart-1, .cart-3, .cart-4, .cart-5, .cart-6, .cart-7 {
  max-width: 92px;
  width: 92px;
}


.cart-8, .cart-9 {
  max-width: 50px;
  width: 50px;
}

.cartpopup {
  position: absolute;
  top: 5%;
  left: 1%;
  width: 98%;
  background: white;
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, .1);
  display: flex;
  flex-direction: column;
  cursor: default;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.popup_container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 10vh;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.popup_header {
  height: 40px;
  padding: 8px;
  width: 100%;
  background-color: rgb(245, 245, 245);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(221, 221, 221);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.cartpopup_close {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.cartpopup_close div {
  width: 20px;
  height: 20px;
  border-radius: 512px;
  background-size: cover;
  background: url(../../src/assets/close.svg);
}

.cartpopup_close:hover {
  background-color: rgb(221, 221, 221);
  border-radius: 512px;
}

.cartpopup_close div:hover {
  background: url(../../src/assets/close-hover.svg);
}

.content {
  background-color: white;
}

.content_header, .content_new_product {
  height: 40px;
  /*background-color: #efefef;*/
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.new_product {
  width: 100%;
  display: flex;
  align-items: center;

}

.new_product label {
  margin: 0 10px;
}

.new_product input {
  height: 30px;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  outline: none !important;
}

.new_product input:focus {

  border: 1px solid #42b983;
  border-radius: 4px;
}

.new_product button {
  margin-right: 10px;
  margin-left: 10px;
  width: 70px;
  height: 30px;
  flex-shrink: 0;
}

#part {
  min-width: 100px;
}

#name {
  min-width: 430px;
  flex-grow: 1;
}

#sum {
  min-width: 100px;
}


.content_product {
  max-height: 75vh;
  margin-top: 5px;
  overflow-y: auto;
  overflow-x: hidden;


}

.content_footer {

  margin-top: 5px;
  display: flex;
  justify-content: flex-end;

}

.removeCart {
  display: flex;
  /*width: 40px;*/
  height: 40px;
  justify-content: center;
  align-items: center;

  cursor: pointer;

}

.removeCart span {
  display: block;
  width: 30px;
  height: 30px;
  background-size: cover;
  background: url(../../src/assets/minus.svg);

}

.removeCart:hover span {
  background: url(../../src/assets/minus-hover.svg);
}

.number {
  display: inline-block;
  position: relative;
  width: 70px;
}

.number input[type="number"] {
  display: block;
  height: 32px;
  line-height: 32px;
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.number input[type="number"]::-webkit-outer-spin-button,
.number input[type="number"]::-webkit-inner-spin-button {
  display: none;
}

.number-minus {
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  width: 20px;
  padding: 0;
  display: block;
  text-align: center;
  border: none;
  border-right: 1px solid #ddd;
  font-size: 16px;
  font-weight: 600;
}

.number-plus {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  width: 20px;
  padding: 0;
  display: block;
  text-align: center;
  border: none;
  border-left: 1px solid #ddd;
  font-size: 16px;
  font-weight: 600;
}

.allchange {
  margin-right: 10px;
}

.allProfit {
  margin-right: 10px;
}

.content_header .vsel {
  height: 30px;
  min-width: 350px;
  border-radius: 8px;
  margin-left: 10px;

}

.submit {
  border: 1px solid grey;
  border-radius: 8px;
  padding: 5px;
  margin: 20px;
  width: 120px;
  height: 50px;
  cursor: pointer;
  display: block;
  text-align: center;
}

.submit:hover {
  background-color: #42b983;
  color: white;
}


.vsel .v-select .vs__dropdown-option--highlight {
  background-color: #42b983;
}

.vsel .v-select .vs__dropdown-menu {
  max-height: 350px;
}

.vsel .v-select #vs8__listbox::-webkit-scrollbar {

  width: 14px;

}

.vsel .v-select #vs8__listbox::-webkit-scrollbar-thumb {

  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #AAAAAA;

}


</style>
