export default {
    namespace: true,
    state: {
        cart: [],
        index: [],
        modalVisible: false,
        modalMessage: '',
        allProfit: 0
    },
    mutations: {
        showModal(state, msg) {
            state.modalVisible = true;
            state.modalMessage = msg;
        },
        hideModal(state) {
            state.modalVisible = false;
        },
        addCart(state, obj) {
            obj.profit = state.allProfit
            state.cart.push(obj)
        },
        addIndex(state, index) {
            state.index.push(index)

        },
        removeCart(state, index) {
            state.cart[index].count = 1
            state.cart[index].profit = 0
            state.cart.splice(index, 1);
        },
        removeIndex(state, index) {
            state.index.splice(index, 1);
        },
        countInc(state, val) {
            state.cart[val.index].count = val.value
        },
        profitInc(state, val) {
            state.cart[val.index].profit = val.value
        },
        profitAll(state, val) {

            state.allProfit = val

        },
        profitAllInc(state, val) {
            for (let i = 0; i < state.cart.length; i++) {
                state.cart[i].profit = val

            }
        },
    },
    actions: {
        // помещаем сюда асинхронные функции, которые могут вызывать одну или несколько функций мутации
    }
}