<template>
  <div>
    <header>
      <div class="header_container">
        <div class="file_input">
          <span @click="auth=true">Выберете прайс</span>
          <section>
            <label for="bvk">Мератех
              <input id="bvk" type="file"

                     @click="tumblerBVK($event)"
                     @change="onChange"
                     accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>

            </label>
            <div class="resetprice"
                 @click="newEvent(1)"
                 v-if="this.miratex.length"
            >

              <div class="image"></div>
            </div>

          </section>
          <section>
            <label for="tdr">Трайдекс
              <input id="tdr"
                     @click="tumblerTridex($event)"
              />

            </label>
            <div class="resetprice"
                 @click="newEvent(2)"
                 v-if="this.tridex.length"
            >

              <div class="image"></div>
            </div>
          </section>
          <section>
            <label for="nix">NIX
              <input id="nix" type="file"
                     @click.stop="tumblerNix($event)"
                     @change="onChangeNix"/>

            </label>
            <div class="resetprice"
                 @click="newEvent(3)"
                 v-if="this.nix.length"
            >

              <div class="image"></div>
            </div>
          </section>

        </div>
        <div class="cart__container">
          <span class="user"
          @click="logout"
          >

        <span class="icon icon-user"
              v-bind:class="{ login: this.$store.state.authModule.authUser }"


        >
        </span>
             </span>


          <div class="cart"
               @click="downloadPopupOpen=true"
          >Загрузить КП
          </div>

          <div class="cart"
               @click="cartopen=true"
          >Корзина
          </div>


        </div>
      </div>
    </header>
    <InfoPopup
        :is-open="$store.state.card.modalVisible"
        @close="popupopen=false"
    >

    </InfoPopup>
    <LoginPopup
        :is-open="!this.$store.state.authModule.authUser"
        @close="auth=false"


    >
    </LoginPopup>
    <ReservedPopup
        :is-open="popupopen"
        @close="popupopen=false"
        @ok="this.addCartStore"

    >
    </ReservedPopup>

    <CartPopup
        :is-open="cartopen"
        :Customers="Customers"
        @close="cartopen=false"
        @confirmExel="this.exportFileExel"
        @confirmWord="this.exportFileWord"

    >

    </CartPopup>


    <DownloadPopup
        :is-open="downloadPopupOpen"
        :Customers="Customers"
        @close="downloadPopupOpen=false"

    >

    </DownloadPopup>

    <div class="container">

      <div class="left_menu">
        <div v-for="(items, index) in dataSort" :key="index"
             class="left_menu_items"

        >
          <div class="left_menu_item"
               @click.stop="items.sub ? clickOpen($event, index) : clickSubMenu(items.id,index)"
          >
            <button class="open_checkbox plus"
                    v-if="items.sub"
            ></button>
            <span
                :class="{subactive: (index === currentIndex) }"
            >{{ items.name }}</span>
          </div>
          <div v-for="(item, ind) in items.sub" :key="ind"
               class="left_submenu"

               @click.stop="item.sub ? clickOpen($event, index, ind) : clickSubMenu(item.id,index, ind)"

          >
            <button
                v-if="item.sub"
                class="open_checkbox plus"></button>
            <span
                :class="{subactive: (ind===currentIndexSub && index === currentIndex) }"
            >{{ item.name }}</span>

            <div v-for="(itemss, inx) in item.sub" :key="inx"
                 class="left_submenu"
                 @click.stop="itemss.sub ? clickOpen($event, index, ind, inx) : clickSubMenu(itemss.id,index, ind, inx)"

            >
              <button
                  v-if="itemss.sub"
                  class="open_checkbox plus"></button>
              <span
                  :class="{subactive: (inx===currentIndexSub1 && ind===currentIndexSub && index === currentIndex) }"
              >{{ itemss.name }}</span>

              <div v-for="(itemx, inq) in itemss.sub" :key="inq"
                   class="left_submenu"


                   @click.stop.capture="clickSubMenu(itemx.id,index, ind, inx, inq)"

              >
              <span
                  :class="{subactive: (inq===currentIndexSub2 && inx===currentIndexSub1 && ind===currentIndexSub && index === currentIndex) }"
              >{{ itemx.name }}</span>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="right_menu">
        <div class="loader" v-if="loading"></div>
        <table class="table">
          <thead>
          <tr>
            <th class="box-1">Парт №</th>
            <th class="box-2"
                @click="order === 'name' ? order = '': order = 'name'"
                :class="order==='name' ? 'active' : ''"

            >Название
            </th>
            <th v-if="specifications"

                class="box-3">Характеристики
            </th>
            <th class="box-4"
                @click="order === 'price' ? order = '': order = 'price'"
                :class="order==='price' ? 'active' : ''"
            >Цена
            </th>
            <th class="box-5">Гар-ия</th>
            <th class="box-6">Резерв</th>
            <th class="box-7">Доб-ть</th>
          </tr>
          </thead>
          <!--          <tbody>-->
          <TransitionGroup name="list" tag="tbody"

          >
            <tr v-for="(items, index) in filteredPriceMenu" :key="index"
                :data-index="index"
            >
              <td>{{ items.sky }}</td>
              <td>{{ items.name }}</td>
              <td
                  v-if="specifications"
              >{{ items.specifications }}
              </td>
              <td>{{ items.priceBN }}</td>
              <td>{{ items.warranty }}</td>
              <td>{{ items.reserve }}</td>
              <td>
                <div class="addCart">

                      <span
                          class="add"
                          @click="addCard($event, index, items.index)"></span>
                </div>
              </td>
            </tr>
          </TransitionGroup>
          <!--          </tbody>-->
        </table>
      </div>
    </div>

  </div>
</template>
<script>
import {read, utils, writeFile} from 'xlsx-js-style';
import {exportDocx} from "@/utils/downTable.js";
// import axios from 'axios'
import InfoPopup from "@/components/info-popup";
import ReservedPopup from "@/components/reserved-popup";
import CartPopup from "@/components/cart-popup";
import DownloadPopup from "@/components/download-popup";
import LoginPopup from "@/components/login-popup";
import {AuthApi} from '@/_api';

export default {
  components: {
    InfoPopup,
    ReservedPopup,
    CartPopup,
    DownloadPopup,
    LoginPopup
  },
  data() {
    return {
      file: null,
      data: {},
      miratex: [],
      nix: [],
      tridex: [],
      currentIndex: -1,
      currentIndexSub: -1,
      currentIndexSub1: -1,
      currentIndexSub2: -1,
      IndexProduct: 0,
      openCheckbox: false,
      popupopen: false,
      cartopen: false,
      order: '1111',
      specifications: true,
      loading: false,
      product: [],
      activeTdx: false,
      Customers: [],
      downloadPopupOpen: false,
      auth: false,
    };
  },
  computed: {

    dataSort() {
      let sorted = Object.values(this.data);
      let a = sorted.sort(function (a, b) {
        var x = a['name'];
        var y = b['name'];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
      return a


    },
    filteredPriceMenu() {
      let sorted = Object.values(this.product);
      if (this.order === 'price') {
        let a = sorted.sort(function (a, b) {
          var x = a['price'];
          var y = b['price'];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
        return a
      }
      if (this.order === 'name') {
        let a = sorted.sort(function (a, b) {
          var x = a['name'];
          var y = b['name'];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
        return a
      }
      return this.product
    }
  },
  async mounted() {
    await this.getCustomer()
  },
  methods: {
    resetIndex() {
      const elems = document.querySelectorAll('.open');
      for (let i = 0; i < elems.length; i++) {
        elems[i].classList.remove("open");
      }
      this.product = []
      this.currentIndex = -1;
      this.currentIndexSub = -1;
      this.currentIndexSub1 = -1;
      this.currentIndexSub2 = -1;
    },

    newEvent(index) {
      if (index === 1) {
        this.miratex = [];
        this.data = [];
        const elem = document.getElementById("bvk");
        elem.value = '';
        this.resetIndex()

      }
      if (index === 2) {
        this.tridex = []
        this.data = []
        const elem = document.getElementById("tdr");
        elem.value = ''
        this.resetIndex()

      }
      if (index === 3) {
        this.nix = []
        this.data = []
        const elem = document.getElementById("nix");
        elem.value = ''
        this.resetIndex()

      }
    },
    tumblerBVK(e) {
      if (this.miratex.length) {
        e.preventDefault()
        this.activeTdx = false
        this.data = this.miratex
        this.specifications = true
        this.resetIndex()
      }
    },
    tumblerNix(e) {
      if (this.nix.length) {
        e.preventDefault()
        this.activeTdx = false
        this.data = this.nix
        this.specifications = false
        this.resetIndex()
      }
    },
    tumblerTridex(e) {
      if (this.tridex.length) {
        e.preventDefault()
        this.specifications = true
        this.data = this.tridex
        this.activeTdx = true
        this.resetIndex()
      } else {
        this.onChangeTdx()
      }
    },

    async onChange(e) {
      this.specifications = true
      const f1 = await (await e.target.files[0]).arrayBuffer();
      const wb = read(f1);
      this.miratex = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
      const priceHeader = []
      this.miratex.forEach((element, index, array) => {
        if (!element.__EMPTY && index > 0 && index < this.miratex.length - 2) {
          if (!array[index + 1].__EMPTY) {
            const subHeader = []
            let product = []
            for (let i = index + 1; i < array.length; i++) {
              if (!array[i].__EMPTY) {
                product = []
                if (i + 1 < this.miratex.length - 1 && !array[i + 1].__EMPTY) {
                  break
                } else {
                  const b = Object.assign({}, {name: Object.values(array[i])[0]}, {index: i})
                  b.prod = product
                  subHeader.push(b)
                }
              } else {
                product.push(Object.assign({},
                    {name: array[i].__EMPTY},
                           {index: "M" + i},
                           {from: "M"},
                           {sky: array[i].__EMPTY_2},
                           {specifications: array[i].__EMPTY_1},
                           {ean: array[i].__EMPTY_3},
                           {price: array[i].__EMPTY_4},
                           {priceBN: parseFloat((parseFloat(array[i].__EMPTY_4) / 1.2).toFixed(2))},
                           {warranty: array[i].__EMPTY_6},
                           {reserve: array[i].__EMPTY_7},
                           {count: 1},
                           {profit: 0}
                ))
              }
            }
            const a = Object.assign({}, {name: Object.values(element)[0]}, {index: index})
            if (subHeader.length) {
              a.sub = subHeader
            }
            priceHeader.push(a);
          }
        }
      });
      this.miratex = priceHeader;
      this.data = this.miratex;
      this.activeTdx = false

    },
    async onChangeNix(e) {
      this.specifications = false
      const f2 = await (await e.target.files[0]).arrayBuffer();
      const wb = read(f2);
      this.nix = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
      const priceHeader = []
      this.nix.forEach((element, index, array) => {
        if (element.__EMPTY) {
          if (array[index + 1].__EMPTY) {
            const subHeader = []
            let product = []
            for (let i = index + 1; i < array.length; i++) {
              if (array[i].__EMPTY) {
                product = []
                if (i + 1 < this.nix.length - 1 && array[i + 1].__EMPTY) {
                  break
                } else {
                  const b = Object.assign({}, {name: Object.values(array[i])[0]}, {index: i})
                  b.prod = product
                  subHeader.push(b)
                }

              } else {
                product.push(Object.assign({},
                    {name: Object.values(array[i])[1]},
                    {index: "N" + i},
                    {from: "N"},
                    {sky: Object.values(array[i])[0]},
                    {price: Object.values(array[i])[4]},
                    {priceBN: Object.values(array[i])[3]},
                    {warranty: Object.values(array[i])[2]},
                    {count: 1},
                    {profit: 0}
                ))
              }
            }
            const a = Object.assign({}, {name: Object.values(element)[0]}, {index: index})

            if (subHeader.length) {
              a.sub = subHeader
            }
            priceHeader.push(a);
          }
        }


      });
      this.nix = priceHeader;
      this.data = priceHeader;
      this.activeTdx = false
    },
    async onChangeTdx() {

      const catalog = await this.requestcatalogTdx()
      const roots = [],
          map = [],
          id = [];
      catalog.forEach(item => {
        map.push(Object.assign({}, item)); // копируем
        id.push(item.id);
      });
      let i;
      map.forEach(item => {
        if (!item.parent_id || (i = id.indexOf(item.parent_id)) === -1) {
          roots.push(item);
          return;
        }
        if (map[i].sub) {
          map[i].sub.push(item);
        } else {
          map[i].sub = [item];
        }
      });
      this.tridex = roots.find(item => item.id === "0000000001").sub
      this.data = this.tridex
      this.activeTdx = true


    },
    logout(){
        this.$store.commit('authModule/delToken');
    },


    async getCustomer() {

      AuthApi.getCustomer().then((res) => {
         this.Customers = res.data
      }).catch((error) => {
        this.$store.commit('showModal', "Список заказчиков не загружен" + error);

      })

    },


    async requestcatalogTdx() {
      try {
        let res = await this.axios.get(
            'https://python.3s.by/offer/getcatalog/',
            {
              headers: {
                'Accept': 'application/json',

              },
            }
        )
        let response = res.data;
        if (response.error) {
          this.$store.commit('showModal', "Каталог Трайдекс не получен ");
        } else {
          return response;
        }


      } catch (error) {

        this.$store.commit('showModal', "Каталог Трайдекс не получен " + error);

      }


    },
    async requestproductsTdx(id) {
      try {
        this.loading = true
        const url = 'https://python.3s.by/offer/getproduct/' + id
        let res = await this.axios.get(
            url,
            {
              headers: {
                'Accept': 'application/json',
              },
            }
        )

        if (res.data === null) {
          this.$store.commit('showModal', "Нет продуктов в данной категории");
          return []
        } else {
          return res.data;
        }

      } catch (error) {

        this.$store.commit('showModal', "Нет запрошенных продуктов");

      } finally {
        this.loading = false

      }


    },

    exportFileWord(sum, sumBN, customer, id) {

      const rows = this.$store.state.card.cart.map((row, index) => ({

        index: index + 1,
        sky: row.sky,
        name: row.name,
        specifications: row.specifications,
        count: parseFloat(row.count),
        priceBN: parseFloat(parseFloat((row.priceBN + (row.priceBN * (row.profit / 100)))).toFixed(2)),
        sumBN: parseFloat(parseFloat((row.priceBN + (row.priceBN * (row.profit / 100))).toFixed(2)
            * row.count).toFixed(2)),
        NDS: parseFloat(parseFloat(((row.priceBN + (row.priceBN * (row.profit / 100)))
            * row.count) / 100 * 20).toFixed(2)),
        sumNDS: parseFloat(parseFloat((row.priceBN + (row.priceBN * (row.profit / 100))).toFixed(2)
            * row.count * 1.2).toFixed(2)),


      }));
      exportDocx("/work.docx", "Коммерческое_предложение.docx", rows, this.currentDate, sum, sumBN, customer, id);

    },


    currentDate() {
      const current = new Date();
      let date = `${current.getDate()}.${current.getMonth() + 1}.${current.getFullYear()}`;
      return date;
    },


    exportFileExel() {

      const rows = this.$store.state.card.cart.map(row => ({

        sky: row.sky,
        name: row.name,
        specifications: row.specifications,
        count: row.count,
        priceBN: row.priceBN,
        sum: row.priceBN * row.count,


      }));


      const worksheet = utils.json_to_sheet(rows, {skipHeader: true, origin: "B5"});


      utils.sheet_set_array_formula(worksheet, "F" + (rows.length + 5), "SUM(F5:F" + (rows.length + 4) + ")");
      utils.sheet_set_array_formula(worksheet, "G" + (rows.length + 5), "SUM(G5:G" + (rows.length + 4) + ")");
      utils.sheet_add_aoa(worksheet, [[{v: "Итого", t: "s"}]], {origin: "B" + (rows.length + 5)});

      const style = {
        fill: {
          fgColor: {rgb: "E9E9E9"}
        },
        font: {
          bold: true,
        },
        alignment: {
          wrapText: true,
          horizontal: "center",
          vertical: "center",
        },
        border: {
          top: {style: 'thin', color: {auto: 1}},
          bottom: {style: 'thin', color: {auto: 1}},
          left: {style: 'thin', color: {auto: 1}},
          right: {style: 'thin', color: {auto: 1}},
        }
      }
      /* fix headers */
      const row1 = [
        {v: "Парт номер", t: "s", s: style},
        {v: "Название", t: "s", s: style},
        {v: "Описание", t: "s", s: style},
        {v: "Кол-во", t: "s", s: style},
        {v: "Цена за ед (без НДС)", t: "s", s: style},
        {v: "Сумма (без НДС)", t: "s", s: style},
      ];
      utils.sheet_add_aoa(worksheet, [row1], {origin: "B4"});


      var wscols = [
        {wch: 1},
        {wch: 15},
        {wch: 40},
        {wch: 40},
        {wch: 5},
        {wch: 10},
        {wch: 10},
      ];

      var range = {s: {c: 0, r: 0}, e: {c: 500, r: 500}};
      worksheet['!ref'] = utils.encode_range(range);
      worksheet['!merges'] = [{s: {c: 1, r: (rows.length + 4)}, e: {c: 4, r: (rows.length + 4)}}];
      worksheet['!cols'] = wscols

      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Dates");
      writeFile(workbook, "Presidents.xlsx",);

    },

    async clickSubMenu(id = -1, index, index1, index2 = 0, index3 = 0) {
      if (!this.loading) {
        this.currentIndex = index
        this.currentIndexSub = index1
        this.currentIndexSub1 = index2
        this.currentIndexSub2 = index3

        if (this.activeTdx && parseInt(id) >= 0) {
          const response = await this.requestproductsTdx(id)
          const product = []
          for (let key in response) {


            product.push(
                Object.assign({}, {name: response[key].name},
                    {index: 'T' + response[key].id},
                    {from: "T"},
                    {sky: response[key].article},
                    {specifications: response[key].description},
                    {price: parseFloat(response[key].price)},
                    {priceBN: parseFloat((parseFloat(response[key].price) / 1.2).toFixed(2))},
                    {reserve: response[key].quantity === 'Резерв' ? 'Резерв' : ''},
                    {count_sklad: response[key].quantity},
                    {count: 1},
                    {profit: 0}
                )
            )

          }
          this.product = product


        } else {
          this.product = this.dataSort[index].sub[index1].prod


        }

      }
    },
    clickOpen(event, index = -1, index1 = -1, index2 = -1) {

      if (!this.loading) {
        this.currentIndex = index
        this.currentIndexSub = index1
        this.currentIndexSub1 = index2
        // event.path.find(item => console.log(item.contains('left_submenu')))
        event.target.parentNode.classList.toggle('open')
      }
      //
      // console.log('find',event.path.find(item => item.className.includes('left_submenu')))


    },
    addCartStore() {

      // console.log(this.filteredPriceMenu)
      this.$store.commit('addIndex', this.filteredPriceMenu[this.IndexProduct].index);
      this.$store.commit('addCart', this.filteredPriceMenu[this.IndexProduct]);
      this.$store.commit('showModal', "Товар " + this.filteredPriceMenu[this.IndexProduct].name + " добавлен в корзину");

    },
    addCard(event, index, realindex) {

      this.IndexProduct = index
      //Одинаковые товары


      if (!this.$store.state.card.index.includes(realindex)) {
        if (this.product[index].reserve) {
          this.popupopen = true

        } else {

          this.addCartStore(realindex)
        }
      } else {
        this.$store.commit('showModal', "Товар уже в корзине");
      }


    },

  }
}
</script>
<style>
.header_container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 8vh;
  border-bottom: 2px solid darkgrey;
}

.file_input {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.file_input span {
  font-weight: bold;
  margin-left: 10px;
}

.file_input input {
  display: none;
}

.file_input label {
  border: 1px solid grey;
  border-radius: 8px;
  padding: 5px;
  width: 120px;
  cursor: pointer;
  display: block;
  text-align: center;
  position: relative;
}

.file_input label:hover, .cart:hover {
  background-color: #42b983;
  color: white;
}

section {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 30px;
  position: relative;
}

.resetprice {
  height: 30px;
  width: 30px;
  position: absolute;
  right: -23px;
  top: 0;
  background-color: white;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid grey;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.resetprice .image:hover {
  background: url(../src/assets/minus-hover.svg);
}

.resetprice .image {
  width: 70%;
  height: 70%;
  background-size: cover;
  background: url(../src/assets/minus.svg);

}

.cart__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart {
  padding: 5px;
  margin: 20px;
  width: 120px;
  border: 1px solid grey;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}

.user {

   width: 30px;
  height: 30px;
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: cover;
  width: 22px;
  height: 22px
}

.icon-user {
  background: url(../src/assets/icon-user.svg)
}

.icon-user.login {
  background: url(../src/assets/icon-user-active.svg)
}

/*----------------Боковой ползунок скрола----------------------*/

.left_menu::-webkit-scrollbar, .content_product::-webkit-scrollbar {
  width: 14px;
}

.left_menu::-webkit-scrollbar-thumb, .content_product::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #AAAAAA;
}

.right_menu::-webkit-scrollbar {
  width: 14px;
}

.right_menu::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #AAAAAA;
}

/* ------------------------------------------------------------*/
.container {
  display: flex;
}

.left_menu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-width: 300px;
  width: 300px;
  border-left: 2px solid darkgrey;
  height: 90vh;
  padding: 5px;
  transform: scaleX(-1);
  overflow-y: scroll;
  overflow-x: hidden;
}

.left_menu_items {
  display: flex;
  transform: scaleX(-1);
  flex-direction: column;
  position: relative;
}

.left_menu_item span.header {
  padding: 0;
  font-weight: bold;

}

.left_menu_item span {
  width: 100%;
  display: block;
  cursor: pointer;
  padding: 3px 0 3px 25px;

}

.plus {
  cursor: pointer;
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
}

.plus:before,
.plus:after {
  content: "";
  position: absolute;
  background: #000;
}

.plus:before {
  left: 50%;
  top: 4px;
  bottom: 4px;
  width: 2px;
  transform: translateX(-50%);
}

.plus:after {
  top: 50%;
  left: 4px;
  right: 4px;
  height: 2px;
  transform: translateY(-50%);
}

.open > .plus:before {
  width: 0;
}

.left_menu_item.open ~ .left_submenu {
  display: block;
}

.open > .left_submenu {
  display: block;
}

.left_submenu {
  position: relative;
  cursor: pointer;
  display: none;
  margin: 0 0 0 30px;
}

.left_submenu span {
  display: block;
  margin: 3px 0;
  width: 100%;
}

.left_submenu .plus {
  left: -25px;
  top: 0
}

.subactive {
  color: #42b983;
}

.subactive > .left_submenu {
  display: block;
}

.right_menu {

  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-left: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 90vh;
  position: relative;
}

.table {
  table-layout: fixed;
  width: 100%;
  margin-bottom: 20px;

}

.table th {
  font-weight: bold;
  padding: 5px;
  background: #efefef;
  border: 1px solid #dddddd;
  position: sticky;
  top: 0;
  z-index: 2;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;

}

.table td {
  padding: 5px 10px;
  border: 1px solid #eee;
  text-align: center;
  word-wrap: break-word;

}

.addCart {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.table .add {
  width: 25px;
  height: 25px;
  background-size: cover;
  display: block;
  background: url(../src/assets/icon-cart.svg);
  cursor: pointer;
}

.table .add:hover {
  background: url(../src/assets/icon-cart-hover.svg);
}

.table tbody tr:nth-child(odd) {
  background: #fff;
}

.table tbody tr:nth-child(even) {
  background: #F7F7F7;
}

.box-4 {
  max-width: 100px;
  width: 100px;
}

.box-1, .box-5, .box-6, .box-7 {
  max-width: 65px;
  width: 65px;
}

.allert {
  position: relative;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.table th.box-4, .table th.box-2 {
  cursor: pointer;

}

.table th.active {
  background-color: #42b983;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.1s ease-in-out;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}


.loader {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #eceaea;
  background-image: url('../src/assets/ajax-loader.gif');
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
</style>