export  const modal ={
    namespace: true,
    state: {
        modalVisible: false,
        modalMessage: '',

    },
    mutations: {
        showModal(state, msg) {
            state.modalVisible = true;
            state.modalMessage = msg;
        },
        hideModal(state) {
            state.modalVisible = false;
        },


    },
    actions: {
        // помещаем сюда асинхронные функции, которые могут вызывать одну или несколько функций мутации
    }
}