<template>
  <div
        v-if="isOpen"
       class="info-popup" @click="close">
    <h3>{{InfoMsg}}</h3>
  </div>

</template>
<script>
// import showModal from "@/store/modal";

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    close: null,
  },
  data() {
    return {}
  },
    computed: {
    InfoMsg () {
      return this.$store.state.modal.modalMessage
  },
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit('hideModal')
    }, 4000);
  },
  methods: {
    close() {
      this.$store.commit('hideModal')
    },
    closeTime() {
      setTimeout(() => {
       this.$store.commit('hideModal')
    }, 4000);
    }
  },
  watch: {
   isOpen: function (newvalue) {
     if(newvalue) {
       this.closeTime()
     }
   }
  },
};
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.info-popup {
  position: absolute;
  z-index: 500;
  top: 10%;
  left: 50%;
  max-width: 780px;
  padding: 0 5px;
  transform: translate(-50%, -50%);
  background: white;
  box-sizing: border-box;
  box-shadow: 0 -2px 5px #4ac3ce,
  0 2px 5px #4ac3ce,
  -2px 0 5px #4ac3ce,
  2px 0 5px #4ac3ce;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  cursor: default;

}

.popup h3 {
  padding: 0;
  color: #000000;
  text-align: center;
}
</style>