<template>
  <div v-if="isOpen" class="backdrop" @mousedown="close">
    <div class="popup" @mousedown.stop>
      <h3>Товар находится в резерве, Вы действительно хотите добавить его в корзину?</h3>
      <div class="button_box">
        <button class="button"
        @click="ok"
        >
          <span>Да</span>
        </button>
        <button class="button blue"
        @click="close"
        >
          <span>Нет</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    close: null,
    ok: null
  },
  data() {
    return {}
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeydown);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleKeydown);
  },
  computed: {},
  methods: {
    handleKeydown(e) {
      if (this.isOpen && e.key === "Escape") {
        this.close();
      }
    },
    close() {
      this.$emit("close");
    },
    ok() {
      this.$emit("ok");
      this.$emit("close");
    },
  },

}
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.popup {
  position: absolute;
  top: 30%;
  left: 50%;
  max-width: 380px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: white;
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, .1);
  display: flex;
  flex-direction: column;
  cursor: default;

}
.popup h3 {
  margin: 0 0 30px;
  padding: 0;
  color: #000000;
  text-align: center;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
}
.button_box{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.button {
  border: none;
  outline: none;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  padding: 12px 16px;
  border-radius: 100px;
  color: #fff;
  background-color: #AAAAAA;
}
.button:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.button:hover {
  background: #42b983;
}

.button:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.button:active {
  background: green;
}
</style>
